@import "~pnotify/src/PNotifyBrightTheme.css";
@import './fontawesome.scss';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


html, body, app-root  {

	padding:0px 0px 0px 0px;
	margin:0px 0px 0px 0px;
	height:auto;

}

app-main-layout {
  flex: 1;
}

.footer {
  display: none!important;
}

.header::before {
  font-family: "Font Awesome 5 Free";
  content: "\f19d";
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root{
   --side-padding : 0em;
   --no-doc_illustration-width:20em;
   --no-doc_illustration-height:20em;

   .main_container {
    padding: 0 var(--side-padding);
   }

   .no-doc_illustration{
    background-image: url("../src/assets/images/illustration_pas-de-doc.svg");
    background-repeat: no-repeat;
    width: var(--no-doc_illustration-width);
    height: var(--no-doc_illustration-height);
    background-size: cover;
    display: flex;
    margin: 10px auto 27px;
  }

  .layout{
    --app-background:#D2E6FC;
  }

   @media only screen and (min-width: 800px) {
    --couleur-document-scolarite: #5A98FF;
    --couleur-background-right-certificat: rgba(39,109,201,1);
    --couleur-background-left-certificat: rgb(120,196,224,1);
    --couleur-notes:#5A98FF;
    --couleur-background-right-notes: rgba(53,141, 58 ,1);
    --couleur-background-left-notes: rgba(128, 201, 131,1);
    --side-padding: 10em;
    //Label des formulaires
    --formulaire-label-color: #C1C1C1;
    --formulaire-paragraphe-font-size: 1.2em;
    //Titre des pages
    --titre-page-font-size: 3.2rem;
    --titre-page-font--weigth: 500;
    --titre-page-color:  #3A87EB;
    //Elements des tableaux
    --tableau-titre-background-color-right: rgba(39,109,201,1);
    --tableau-titre-background-color-left: rgb(120,196,224,1);
    --tableau-titre-padding-left: 0.7vw;
    --tableau-titre-font-size: 1.9rem;
    --tableau-sous-titre-font-size: 1.6rem;
    --tableau-ligne-font-size: 1.4rem;

    .mat-elevation-z8 {
      box-shadow: none!important;
    }

   }

   @media screen and (max-width: 799px) {
    .layout{
      padding-bottom: 5em;
    }

    .picto-consult.fas {
      margin: 0;
    }

    --couleur-document-scolarite: #5A98FF;
    //Label des formulaires
    --formulaire-label-color: #C1C1C1;
    --formulaire-label-font-size: 0.5;
    --formulaire-paragraphe-font-size: 1em;

    --tableau-titre-font-size: 1.7rem;
    --tableau-sous-titre-font-size: 1.2rem;
    --tableau-ligne-font-size: 1rem;

    --no-doc_illustration-width:12em;
   --no-doc_illustration-height:12em;
   }
}
